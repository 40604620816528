import { useAppSelector as useSel } from '@root/store'
import {
  selectContentById,
  selectLivePropByContentId as selLive,
} from '@store/contents-slice/_selectors'
import { addSeconds } from 'date-fns'
import { useMemo } from 'react'

interface Props {
  contentId: string
}

/**
 * Get the live stream times for a content
 * @param contentId
 * @returns liveStartTime, liveScheduledStartTime, liveStartTimeToUse, liveEndTime, liveReplayAvailability
 */
export default function useGetLivestreamTimes({ contentId }: Readonly<Props>) {
  const liveStartTime = useSel(selLive(contentId)('startTime'))
  const liveScheduledStartTime = useSel(selLive(contentId)('scheduledStartTime'))

  // Use liveStartTime if it exists, otherwise use scheduledStartTime
  const liveStartTimeToUse = liveStartTime ?? liveScheduledStartTime

  const liveEndTime = useSel(selLive(contentId)('endTime'))
  const scheduledEndTime = useSel(selLive(contentId)('scheduledEndTime'))
  const liveReplayAvailability = useSel(selLive(contentId)('replayAvailability'))

  const liveScheduledExpiry = useMemo(() => {
    if (liveReplayAvailability && scheduledEndTime) {
      // Add replayAvailability to the scheduledEndTime
      const replayEndTime = addSeconds(new Date(scheduledEndTime), liveReplayAvailability)

      // Determine the maximum time between endTime and replayEndTime
      if (liveEndTime) {
        const endTimeDate = new Date(liveEndTime)
        return endTimeDate > replayEndTime ? endTimeDate : replayEndTime
      } else {
        return replayEndTime
      }
    } else {
      // Use endTime if it exists, otherwise use scheduledEndTime
      return liveEndTime ?? scheduledEndTime
    }
  }, [liveEndTime, scheduledEndTime, liveReplayAvailability])

  // Return the values
  return {
    liveStartTime,
    liveScheduledStartTime,
    liveStartTimeToUse,
    liveEndTime,
    liveScheduledExpiry,
    liveReplayAvailability,
  }
}
