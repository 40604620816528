import style from './common.module.css'
import { ButtonWrapper } from '../../_buttons'
import { Img } from '../../_images/img'
import { generateTransformationUrl } from '@apis/cloudinary'
import type { CSSProperties } from 'react'
import { DEFAULT_WIDTH_CREATOR_THUMB } from '@root/constants'
import { applyScaleToCropImage, hasCropVersion } from '@root/utils/cloudinary'
import { PREVIEW_CHANNEL_AVATAR } from '@shared/constants/root.constants'
import { useAppSelector as useSel } from '@root/store'
import { selectChannelData } from '@store/channel-slice/_selectors'
import { getHeightFromWidthAndAspect } from '@root/utils/image'

export type Variant = 'avatar' | 'card' | 'channel-avatar'

export type Props = {
  channelPathname?: string
  logoSrc: string
  logoAlt: string
  isLink?: boolean
  variant?: string
  className?: string
  wrapperClassName?: string
  style?: CSSProperties
  width?: number
}

export function CreatorLogo({
  logoSrc,
  logoAlt,
  channelPathname,
  isLink = false,
  variant = 'avatar',
  className = style.defaultClassName,
  wrapperClassName,
  width = DEFAULT_WIDTH_CREATOR_THUMB,
}: Props): JSX.Element {
  const logoToUse = logoSrc ?? PREVIEW_CHANNEL_AVATAR

  const imageUrl =
    hasCropVersion(logoToUse) || logoToUse === PREVIEW_CHANNEL_AVATAR
      ? logoToUse
      : generateTransformationUrl(logoToUse, { width: width, aspectRatio: '1:1' })

  const channel = useSel(selectChannelData(channelPathname))

  // When the channel is added to the channel list, we need to redirect to the preview in case is draft
  const linkToUse =
    channel && channel.channelStatus === 'draft' ? 'preview/channel' : `/${channelPathname}`

  return (
    <ButtonWrapper className={wrapperClassName} href={isLink ? linkToUse : undefined}>
      <Img
        alt={logoAlt}
        src={imageUrl}
        aspectRatio="1/1"
        className={[style.logo, style[variant], className].join(' ')}
      />
    </ButtonWrapper>
  )
}
