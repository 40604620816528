import { getLiveExpiryDate } from '@root/utils/live-utils'

// All content types contain these schedule states
export type ContentScheduleState = 'pre-sale' | 'live' | 'expired' | 'premium' | 'available'
// Live specific content schedules states
export type LiveContentScheduleState = ContentScheduleState | 'replay' | 'live-delayed'

/**
 * Function to return the scheduled state for content
 * (any content type excluding live) to help when rendering
 * the content-badge and time schedule components
 * @param startDateTimeString
 * @param endDateTimeString
 */
export function contentScheduleState(
  startDateTimeString: string,
  endDateTimeString: string,
): ContentScheduleState {
  // Create dates to use for evaluation below
  const currentDateTime = new Date()
  const startDateTime = new Date(startDateTimeString)
  const endDateTime = new Date(endDateTimeString)

  // Evaluate dates against current time
  if (currentDateTime < startDateTime) {
    return 'pre-sale'
  } else if (currentDateTime > startDateTime && currentDateTime < endDateTime) {
    return 'live'
  } else if (currentDateTime > endDateTime) {
    return 'expired'
  }
}

/**
 * Function to return the scheduled state for a live stream to
 * help when rendering the content-badge and time schedule components
 * @param request
 */
export function liveContentScheduleState(request: {
  startDateTimeString: string
  endDateTimeString: string
  liveScheduledExpiry: string | Date
}): LiveContentScheduleState {
  const { startDateTimeString, endDateTimeString, liveScheduledExpiry } = request

  // Create dates to use for evaluation below
  const currentDateTime = new Date()
  const startDateTime = new Date(startDateTimeString)
  const endDateTime = new Date(endDateTimeString)

  // Evaluate dates against current time
  if (currentDateTime < startDateTime) {
    return 'pre-sale'
  } else if (currentDateTime > endDateTime && currentDateTime < liveScheduledExpiry) {
    return 'replay'
  } else if (currentDateTime > liveScheduledExpiry) {
    return 'expired'
  } else {
    return 'live'
  }
}
