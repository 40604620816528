import Link from 'next/link'
import style from './common.module.css'
import { inheritFont } from '@styles/_class-names'
import type { HTMLAttributeAnchorTarget, ReactNode } from 'react'
import { clearLocalStorageBackupCart } from '@library/_flows/_checkout-video-flow/_utilities/clear-local-storage-backup-cart'

export type Props = {
  children: ReactNode
  className?: string
  href?: string
  target?: HTMLAttributeAnchorTarget
}

/**
 * An inline anchor link wrapper.
 * @description
 * If href is defined, render a link. <br>
 * If href is not undefined, render the children. <br>
 * The difference between this and the ButtonWrapper is this is an inline element,
 * while ButtonWrapper is a block element.
 */
export function LinkWrapper({
  children,
  href,
  target = '_self',
  className = style.defaultClassName,
}: Props): JSX.Element {
  return (
    <>
      {href === undefined ? (
        children
      ) : (
        <Link href={href}>
          <a
            className={[inheritFont, className].join(' ')}
            rel={target === '_self' ? undefined : 'noreferrer'}
            target={target}
          >
            {children}
          </a>
        </Link>
      )}
    </>
  )
}
