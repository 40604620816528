import { Hn } from '../../hn'
import { LinkWrapper } from '../../_links/link-wrapper'
import type { HnVariant } from '../../hn'
import { useTitle } from '@library/_content/content-title/hooks/use-title'

interface Props {
  channelPathname: string
  contentId?: string
  hn?: HnVariant
  isLink?: boolean
  className?: string
  titleText?: string
  subscriptionId?: string
  passId?: string
}

export function ContentTitle({
  contentId,
  passId,
  subscriptionId,
  channelPathname,
  hn,
  isLink = false,
  className,
  titleText,
}: Props): JSX.Element {
  // Grabs a title from either the content or the pass/subscription (whatever is not undefined)
  const title = useTitle(contentId, passId, subscriptionId, channelPathname)

  return (
    <Hn hn={hn} className={className}>
      <LinkWrapper href={isLink ? `/${channelPathname}/${contentId}` : undefined}>
        {titleText ?? title}
      </LinkWrapper>
    </Hn>
  )
}
