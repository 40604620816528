import { Fragment, ReactNode } from 'react'

export interface Props {
  isTrue: boolean
  children: ReactNode
  trueComponent?: ReactNode
}

export default function RenderIf({ isTrue, children, trueComponent }: Props): JSX.Element {
  if (trueComponent) return <Fragment>{isTrue ? trueComponent : children}</Fragment>
  else return <Fragment>{isTrue ? children : null}</Fragment>
}
