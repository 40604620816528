import { Temporal } from '@js-temporal/polyfill'
import { isGreaterThanZero } from '../connascence'

/**
 * Function to take duration in seconds and humanize to hours and minutes
 * @param durationInSeconds
 */
export function formatShortDuration(durationInSeconds: number): string {
  // Get duration
  const duration = Temporal.Duration.from({ seconds: durationInSeconds }).round({
    largestUnit: 'hours',
    smallestUnit: 'seconds',
  })

  let hours: string
  let minutes: string
  let seconds: string
  // If duration less than or equal to 120mins then show only mins otherwise hours and mins
  if (duration.hours >= 2 || (duration.hours >= 1 && duration.minutes > 30)) {
    hours = duration.hours + 'h'
    minutes = duration.minutes && duration.minutes + 'm'
  } else if (duration.hours >= 1 && duration.minutes <= 30) {
    minutes = duration.hours * 60 + duration.minutes + 'm'
  } else if (isGreaterThanZero(duration.minutes)) {
    minutes = duration.minutes + 'm'
  } else {
    seconds = duration.seconds + 's'
  }

  return [hours, minutes, seconds].filter((e) => e).join(' ')
}
