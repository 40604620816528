import style from './common.module.scss'
import { ButtonWrapper } from '../../_buttons'
import { Img } from '../../_images/img'
import { selectContentPropByContentId as sel } from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import type { ImgHTMLAttributes, ReactNode } from 'react'
import { DEFAULT_WIDTH_THUMB } from '@root/constants'
import VodAudioDurationBadge from '@features/_ui/duration-badge/vod-audio'
import RenderIf from '@root/utils/render-if'
import { buildThumbnailImageToUse } from '@root/utils/image'

export type Variant = 'default'

type CustomProps = {
  contentId: string
  href?: string
  onClick?: () => void
  backgroundColor?: string
  wrapperClass?: string
  className?: string
  width?: number
  playButton?: ReactNode
  showDuration?: boolean
  bundleListThumb?: boolean
  thumbProps?: {
    thumbnailSrc: string
    thumbnailAlt: string
    title: string
  }
  customDurationBadge?: JSX.Element
}

type OtherImgProps = Omit<ImgHTMLAttributes<HTMLImageElement>, keyof CustomProps>

export type Props = CustomProps & OtherImgProps

export function Thumbnail({
  contentId,
  href,
  onClick,
  backgroundColor,
  wrapperClass,
  className,
  width = DEFAULT_WIDTH_THUMB,
  playButton,
  showDuration = true,
  bundleListThumb = false,
  thumbProps,
  customDurationBadge,
  ...otherImgProps
}: Props): JSX.Element {
  let thumbnailSrc = useSel(sel(contentId)('thumbnail')) ?? ''
  let thumbnailAlt = useSel(sel(contentId)('thumbnailAlt')) ?? ''
  let title = useSel(sel(contentId)('title')) ?? 'Title'

  if (thumbProps) {
    ;({ thumbnailSrc, thumbnailAlt, title } = thumbProps)
  }

  const thumbnailToUse = buildThumbnailImageToUse(thumbnailSrc, width, '16:9')

  const durationBadge = customDurationBadge ?? (
    <VodAudioDurationBadge contentId={contentId} size={bundleListThumb ? 'small' : 'normal'} />
  )

  return (
    <ButtonWrapper href={href} onClick={onClick} className={[style.button, wrapperClass].join(' ')}>
      <div className={style.wrapperPlayer}>
        <Img
          alt={thumbnailAlt || title}
          src={thumbnailToUse}
          aspectRatio={'16/9'}
          backgroundColor={backgroundColor}
          className={className}
          {...otherImgProps}
        />
        {playButton && <div className={style.playButtonWrapper}>{playButton}</div>}
        <RenderIf isTrue={showDuration}>
          <div className={style.imageBadge}>{durationBadge}</div>
        </RenderIf>
      </div>
    </ButtonWrapper>
  )
}
