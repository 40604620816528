import styles from './common.module.css'
import { useAppSelector as useSel } from '@root/store'
import {
  selectAudioPropByContentId,
  selectContentPropByContentId as sel,
  selectVodPropByContentId,
} from '@store/contents-slice/_selectors'
import { contentScheduleState } from '@root/utils/content-schedule-state'
import { isGreaterThanZero } from '@root/utils/connascence'
import DurationBox from '@features/_ui/box/duration-box'
import { formatShortDuration } from '@root/utils/format-short-duration'

export interface Props {
  contentId: string
  size?: 'small' | 'normal'
}

export default function VodAudioDurationBadge({ contentId, size = 'normal' }: Props): JSX.Element {
  // Data for vod duration display
  const contentType = useSel(sel(contentId)('contentType'))
  const vodLength = useSel(selectVodPropByContentId(contentId)('length'))
  const audioLength = useSel(selectAudioPropByContentId(contentId)('length'))
  const startDate = useSel(sel(contentId)('scheduledReleaseDate'))
  const endDate = useSel(sel(contentId)('scheduledExpiryDate'))

  const renderVodBadge = (): boolean => {
    const scheduleState = contentScheduleState(startDate, endDate)
    switch (contentType) {
      case 'vod':
        return isGreaterThanZero(vodLength) && scheduleState !== 'expired'
      case 'audio':
        return isGreaterThanZero(audioLength) && scheduleState !== 'expired'
      default:
        return false
    }
  }

  const getDuration = () => {
    switch (contentType) {
      case 'vod':
        return formatShortDuration(vodLength)
      case 'audio':
        return formatShortDuration(audioLength)
      default:
        return undefined
    }
  }

  return (
    renderVodBadge() && (
      <DurationBox size={size}>
        <span className={[styles.duration, styles[size]].join(' ')}>{getDuration()}</span>
      </DurationBox>
    )
  )
}
