import styles from './common.module.css'
import { ReactNode } from 'react'

export interface Props {
  children: ReactNode
  size?: 'small' | 'normal'
}

export default function DurationBox({ children, size }: Props): JSX.Element {
  return <div className={[styles.wrapper, styles[size]].join(' ')}>{children}</div>
}
