import type { ReactNode } from 'react'

export type HnVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type Props = {
  hn?: HnVariant
  children: ReactNode
  className?: string
}

function renderHn({ hn, children, className }: Props): JSX.Element {
  switch (hn) {
    case 'h1':
      return <h1 className={className}>{children}</h1>
    case 'h2':
      return <h2 className={className}>{children}</h2>
    case 'h3':
      return <h3 className={className}>{children}</h3>
    case 'h4':
      return <h4 className={className}>{children}</h4>
    case 'h5':
      return <h5 className={className}>{children}</h5>
    case 'h6':
      return <h6 className={className}>{children}</h6>
    default:
      return <div className={className}>{children}</div>
  }
}

export function Hn(props: Props): JSX.Element {
  return renderHn(props)
}
