import { useAppSelector as useSel } from '@store/index'
import {
  selectPassDataByPassId as selPass,
  selectSubscriptionDataBySubscriptionId as selSubscription,
} from '@store/channel-slice/_selectors'
import { selectContentPropByContentId as selContent } from '@store/contents-slice/_selectors'

export const useTitle = (
  contentId?: string,
  passId?: string,
  subscriptionId?: string,
  channelPathname?: string,
) => {
  const contentTitle = useSel(selContent(contentId)('title'))
  const pass = useSel(selPass(channelPathname, passId))
  const subscription = useSel(selSubscription(channelPathname, subscriptionId))

  // Set the title to one found
  const title = contentTitle ?? pass?.title ?? subscription?.title ?? ''

  return title
}
