import style from '@features/_ui/content-badge/common.module.css'
import {
  selectContentPropByContentId as selProp,
  selectExternalLinkPropByContentId,
} from '@store/contents-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import {
  contentScheduleState,
  liveContentScheduleState,
  LiveContentScheduleState,
} from 'utils/content-schedule-state'
import Badge from '@features/_ui/badge'
import RenderIf from '@utils/render-if'
import { ReactElement } from 'react'
import useGetLivestreamTimes from '@hooks/use-get-livestream-times'

export interface Props {
  contentId: string
  className?: string
}

export function ContentBadge({ contentId, className }: Readonly<Props>): ReactElement {
  const contentType = useSel(selProp(contentId)('contentType'))
  const premium = useSel(selProp(contentId)('premium'))

  // Hooks
  const { liveStartTimeToUse, liveEndTime, liveScheduledExpiry } = useGetLivestreamTimes({
    contentId,
  })

  // External link schedules
  const externalLinkStartTime = useSel(
    selectExternalLinkPropByContentId(contentId)('scheduledStartTime'),
  )
  const externalLinkEndTime = useSel(
    selectExternalLinkPropByContentId(contentId)('scheduledEndTime'),
  )

  // Vod & bundle schedules
  const vodOrBundleStartDate = useSel(selProp(contentId)('scheduledReleaseDate'))
  const vodOrBundleEndDate = useSel(selProp(contentId)('scheduledExpiryDate'))

  let badgeType: LiveContentScheduleState = undefined
  switch (contentType) {
    case 'live':
      badgeType = liveContentScheduleState({
        startDateTimeString: liveStartTimeToUse,
        endDateTimeString: liveEndTime,
        liveScheduledExpiry,
      })
      break
    case 'vod':
    case 'audio':
    case 'bundle':
      if (contentScheduleState(vodOrBundleStartDate, vodOrBundleEndDate) === 'expired') {
        badgeType = 'expired'
        break
      }
      break
    case 'external-link':
      badgeType = contentScheduleState(externalLinkStartTime, externalLinkEndTime)
      break
  }

  const labelMap: Record<LiveContentScheduleState, string> = {
    ['pre-sale']: 'LIVE',
    live: 'LIVE',
    replay: 'REPLAY',
    expired: 'EXPIRED',
    premium: 'PREMIUM',
    available: 'AVAILABLE',
    ['live-delayed']: 'LIVE',
  }

  return (
    <RenderIf isTrue={premium || badgeType !== undefined}>
      <div className={[style.wrapper, className].join(' ')}>
        {premium && <Badge variant={'premium'} title={labelMap['premium']} />}
        {badgeType !== undefined && <Badge variant={badgeType} title={labelMap[badgeType]} />}
      </div>
    </RenderIf>
  )
}
