import styles from './common.module.css'
import { LiveContentScheduleState } from '@root/utils/content-schedule-state'
import { ReactElement } from 'react'

export interface Props {
  variant: LiveContentScheduleState
  title: string
}

export default function Badge({ variant, title }: Readonly<Props>): ReactElement {
  return <span className={[styles.text, styles[variant]].join(' ')}> {title}</span>
}
